import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_red.css";
import Spinner from "react-bootstrap/Spinner"
import Image from "next/image"
import { useState, useEffect, useRef } from "react"
import userStyles from "../../../../styles/Userpage.module.css"
import { CheckLg, ArrowLeft, PersonCircle, CameraVideoFill, ClockFill, CurrencyDollar, CalendarWeek, ArrowDown, EnvelopeFill, XLg, LockClosedIcon } from 'react-bootstrap-icons'
import { supabase } from '../../../../utils/supabaseClient'
import { useAlert } from 'react-alert'
import { dev, cloudfront, server } from "../../../../config"
import { TimezoneSelect, clientTz } from 'timezone-select-js';
import * as ga from '../../../../lib/gtag'
import validator from 'validator'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
const { DateTime } = require("luxon");
import {useAuth} from  "../../../../contexts/Auth"
import Link from "next/link"


const RegisterSlide = (props) => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : "America/Los_Angeles"
  const [form, setForm] = useState({"name":null, "account_type": "business", "email": "", 'password': null, 'confirm_password':null})
  const [loading, setLoading] = useState(false)
  const [errorObj, setErrorObj] = useState({emailInvalid:null, passwordInvalid:null, nameInvalid:null})

  const handleChange = (e) => {
    const {name, value} = e.target
    let newForm = {...form}
    newForm[name] = value
    setForm(newForm)

}

const checkErrors = () => {
  if (form.password !== form.confirm_password) {
      let newError = {...errorObj}
      newError.passwordInvalid = "Passwords do not match"
      setErrorObj(newError)
      return false
  }
  if (form.password.length < 8) {
    let newError = {...errorObj}
    newError.passwordInvalid = "Password too short"
    setErrorObj(newError)
      return false
  } 
  if (!form.name) {
    let newError = {...errorObj}
    newError.nameInvalid = "Please enter a username"
    setErrorObj(newError)
    return false
  }

  return true
}
const submitRegister = async (e) => {
  e.preventDefault()
  setLoading(true)
  props.setCheckUser(false)

  const bool = checkErrors()
  if (!bool) {
      setLoading(false)
      return
  }

  const { user, error } = await supabase.auth.signUp({
      email: form.email,
      password: form.password,
    })


  if (!error) {
      ga.event({
        action:'client_register',
      })

      const rand = (Math.random() + 1).toString(36).substring(7)
      let payload = {user_id:user.id, name:form.name, account_type:"client", email: form.email, unique_slug: rand, timezone: tz }
      const profile = await supabase
          .from("main_profile")
          .insert(payload)
      
      //update state here?
      props.handleUser({email: form.email, name:form.name, id: user.id, profile_id: profile.data[0].id})

        if (props.conversation) {
         //if convo add profile to convo
            const convo = await supabase
            .from("main_conversation")
            .update({client_profile_id: profile.data[0].id})
            .match({uuid: props.conversation})
        }


      //send email 
      await fetch("/api/contact/register", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body:JSON.stringify({
          email: form.email,
          client: true,
          coach_name: props.profile.name,
          brand_logo : props.profile.brand_logo ? props.profile.brand_logo : null
        })
      })
   
      
      
      props.setSubPage(0)
     

      
  } else {
    let newError = {...errorObj}
    newError.emailInvalid = error.message
    setErrorObj(newError)
  }

}

  return (
    <form className=" space-y-6"  method="POST">
            {/* <input type="hidden" name="remember" defaultValue="true" /> */}
            <h3 className='font-semibold text-xl text-slate-900'>Register to sign up for this mentor&apos;s services</h3>
            <div className="shadow-sm  rounded-md ">
            <div  className='mb-3'>
                <p className='label'>
                    Name
                </p>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className={`${errorObj.nameInvalid ? ' focus:ring-pink-500 text-pink-600' : ' focus:border-slate-500 focus:ring-slate-500 '} shadow-sm mt-1 z-10 appearance-none  relative block w-full px-3 py-2 border border-slate-300 rounded-md  focus:outline-none focus:ring-1 focus:z-10 sm:text-sm`}
                  onChange={handleChange}
                />
              </div>
              <div className='mb-3'>
                <p className='label'>
                  Email address
                </p>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  defaultValue={form.email}
                  autoComplete="email"
                  required
                  className={`${errorObj.emailInvalid ? ' focus:ring-pink-500 text-pink-600' : ' focus:border-slate-500 focus:ring-slate-500 '} shadow-sm mt-1 z-10 appearance-none  relative block w-full px-3 py-2 border border-slate-300 rounded-md  focus:outline-none focus:ring-1 focus:z-10 sm:text-sm`}
       
                  onChange={handleChange}
                />
              </div>
              <div  className='mb-3'>
              <p className='label'>
                  Password
                </p>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`${errorObj.passwordInvalid ? ' focus:ring-pink-500 text-pink-600' : ' focus:border-slate-500 focus:ring-slate-500 '} shadow-sm mt-1 z-10 appearance-none  relative block w-full px-3 py-2 border border-slate-300 rounded-md  focus:outline-none focus:ring-1 focus:z-10 sm:text-sm`}
                  onChange={handleChange}
                />
              </div>
              <div  className='mb-3'>
              <p className='label'>
                  Confirm Password
                </p>
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`${errorObj.passwordInvalid ? ' focus:ring-pink-500 text-pink-600' : ' focus:border-slate-500 focus:ring-slate-500 '} shadow-sm mt-1 z-10 appearance-none  relative block w-full px-3 py-2 border border-slate-300 rounded-md  focus:outline-none focus:ring-1 focus:z-10 sm:text-sm`}
                  onChange={handleChange}
                />
              </div>
            </div>
            {errorObj.nameInvalid &&<span className="block text-pink-600">{errorObj.nameInvalid}</span>}
            {errorObj.passwordInvalid &&<span className="block text-pink-600">{errorObj.passwordInvalid}</span>}
            {errorObj.emailInvalid &&<span className="block text-pink-600">{errorObj.emailInvalid}</span>}


            <div>
            <span className="text-slate-600" style={{fontSize:'13px'}}>By registering for a Plaza account, you agree to our <Link href="/terms-conditions" passHref><a  target="_blank" className="underline underline-4">terms and conditions</a></Link></span>
              <button
                onClick={submitRegister}
                disabled={loading}
                type="submit"
                className="group relative w-full flex mt-2 justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-slate-700 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-zinc-500 group-hover:text-zinc-400" aria-hidden="true" /> */}
                </span>
                { loading ? 
                 <svg className="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> 
                : 
                'Register'}
              </button>
            </div>
          </form>
  )

}

const SendMessage = (props) => {
  const [success, setSuccess] = useState(null)
  const [message, setMessage] = useState({ name: "", email: "", message: "" })
  const [loading, setLoading] = useState(false)
  const [error, setError ] = useState(null)   //update error to handle multiple cases


  const handleMessage = (e) => {
    e.preventDefault()
    let newMessage = { ...message }
    newMessage[e.target.name] = e.target.value
    setMessage(newMessage)
    
  }
  const submitMessage = async () => {
    setLoading(true)
    let cleanMessage = { ...message }

    if (!validator.isEmail(cleanMessage.email)) {
      setLoading(false)
      setError("Invalid Email")
      return
    } 
    error && setError(null)

    
    const resp = await fetch(`${server}/api/contact/messages/newMessage`, {
      headers: {'Content-Type': 'application/json'},
      method: "POST",
      body: JSON.stringify({
        name: cleanMessage.name,
        email: cleanMessage.email,
        message: cleanMessage.message,
        profile_id: props.profile.id,
        activity: {
          id: props.activity.id,
          name: props.activity.booking_name
        },
        source: "booking page"  //need to add source?
      })

    })
    setLoading(false)
    setSuccess(resp.ok)
 
    
    

  }

  return (
    <div className=' p-4 rounded-lg ' style={{ minHeight: "200px" }}>
      <h2 className="text-slate-700 font-bold text-2xl mb-1">Send Message</h2>
      {success ?
        <div className="pt-5 mt-5 pb-12  text-center">
          <CheckLg className="mx-auto mb-2 text-green-600" size={45} />
          <p className="font-bold text-xl mb-3 text-slate-700">Message Sent</p>
            <p className='text-slate-900'>You&apos;ll receive a response via email<br />
            
          </p>
        
        </div>

        :
         success === false ?
         <div className="pt-5 mt-5 pb-12  text-center">
         <XLg className="mx-auto mb-2 text-red-500" size={24} />
           <p className='text-slate-900 font-semibold text-lg'>An error occurred</p>
            <p className='text-slate-600 mb-4'>Please refresh the page and try again.  If this error persists, please email us as info@plaza.so</p>
      
        
       </div>
         
         :


        <div className='text-left'>
          <div className="mb-3">
            <p className="label">Name</p>
            <input
              type="text"
              required
              name="name"
              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
              defaultValue={message.name}
              onChange={handleMessage}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <div className='flex justify-between'>
            <p className="label">Email</p>
            {error &&    <p className="text-sm text-red-500">{error}</p>}
            </div>
            <input
              type='email'
              required
              name="email"
              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
              disabled={loading}
              defaultValue={message.email}
              onChange={handleMessage}
            />
          </div>
          <div className="mb-3">
            {/* {props.business && " (Ex: request a template or demo) "} */}
            <p className="label">Message</p>
            <textarea
              rows={3}
              size="sm"
              name="message"
              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
              defaultValue={message.message}
              onChange={handleMessage}
              disabled={loading}
            />
          </div>
          <button
            className="w-full mt-2 bg-slate-700 shadow-md shadow-slate-500/50 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-zinc-900"
            onClick={submitMessage} disabled={loading}>
            {loading ? <div className="flex justify-center"><svg className="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg></div> : 'Send Message'}
          </button>
        </div>

      }
    </div>
  )
}

const Checkout = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert()
  const { user } = useAuth()

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }



    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: server + "/meeting/confirmed/" + props.activity.id,
      },
      redirect: 'if_required',
    });

    //check here to set confirmed message if no return url
    if (!error) {
      const meeting = props.meeting
      const { dateStr, appointment_datetime, timezone, meeting_dates } = meeting
      const durationInMinutes = props.activity.duration_period === "minutes" ? props.activity.duration_int : props.activity.duration_int * 60
      const duration_obj = { minutes: durationInMinutes }
      const numWeeks = props.activity.schedule_period === "week" ? props.activity.schedule_int : props.activity.schedule_int * 4
      const appointmentDateTime = DateTime.fromISO(appointment_datetime)
      let mData
      let payload
      let uuid
      let utc_date
      let url
      let runAt
      let remindAt
      const dateSubscription = DateTime.now().toFormat("yyyy-LLL-dd-HH-mm")


      if (!props.activity.multiple) {
        mData = {
          v_appointment_datetime: appointmentDateTime.setZone(props.meeting.timezone).toLocaleString(DateTime.DATETIME_FULL),
          c_appointment_datetime: appointmentDateTime.setZone(props.activity.timezone).toLocaleString(DateTime.DATETIME_FULL),
        }
        utc_date = appointmentDateTime.toUTC().toFormat('y-L-d-H-m').split("-").map(str => parseInt(str))
        payload = {
          appointment_datetime: appointment_datetime,
          viewer_name: meeting.name,
          viewer_email: meeting.email,
          notes: meeting.notes,
          status: 'upcoming',
          duration: durationInMinutes,
          viewer_timezone: timezone,
          type: '1-on-1',
          cost: props.activity.meeting_cost,
          subtype: meeting.rand,
          user_id: props.activity.user_id,
          activity: props.activity.id,
          client_user_id: user ? user.id : null
        }
      } else {
        mData = []
        payload = []
        utc_date = []
        for (const m of meeting_dates) {
          const payloadMeeting = {
            appointment_datetime: m,
            viewer_name: meeting.name,
            viewer_email: meeting.email,
            notes: meeting.notes,
            status: 'upcoming',
            duration: durationInMinutes,
            viewer_timezone: timezone,
            type: '1-on-1',
            cost: props.activity.meeting_cost,
            subtype: meeting.rand,
            user_id: props.activity.user_id,
            activity: props.activity.id,
            client_user_id: user ? user.id : null
          }
          const mObj = {
            v_appointment_datetime: DateTime.fromISO(m).setZone(props.meeting.timezone).toLocaleString(DateTime.DATETIME_FULL),
            c_appointment_datetime: DateTime.fromISO(m).setZone(props.activity.timezone).toLocaleString(DateTime.DATETIME_FULL),
            iso: m,
          }
          mData.push(mObj)
          payload.push(payloadMeeting)
        }
        for (let i = 0; i < props.activity.weekly_meeting_amount; i++) {
          const meetingUTC = DateTime.fromISO(meeting_dates[i]).toUTC().toFormat('y-L-d-H-m').split("-").map(str => parseInt(str))
          utc_date.push(meetingUTC)
        }
      }


      const meetingRes = await supabase
        .from("main_meeting")
        .insert(payload)

      const newMeeting = meetingRes.data[0]

      if (props.activity.multiple) {
        url = '/api/contact/multiple'
        runAt = DateTime.now().plus({ minutes: 1 }).toISO()
        uuid = []
        for (let i = 0; i < newMeeting.length; i++) {
          const apptDt = mData[i].iso
          mData[i].uuid = newMeeting.filter(obj => Date.parse(apptDt) === Date.parse(obj.appointment_datetime))[0].room_uuid
        }

      } else {
        mData.uuid = newMeeting.room_uuid
        url = '/api/contact/demo'
        runAt = appointmentDateTime.setZone(props.meeting.timezone).plus({ minutes: -5 }).toISO()
        remindAt = appointmentDateTime.setZone(props.meeting.timezone).plus({ minutes: -60 }).toISO()
      }


      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: newMeeting.viewer_email,
          meeting_data: mData,
          utc_date: utc_date,
          creator_email: props.activity.profile.email,
          notes: newMeeting.notes,
          number_of_weeks: numWeeks,
          weekly_amount: props.activity.weekly_meeting_amount,
          iso_dates: meeting_dates.slice(0, props.activity.weekly_meeting_amount),//used to setup recurring meetings
          duration_obj: duration_obj,
          subtype: meeting.rand,
          creator_name: props.activity.profile.name,
          creator_timezone: props.activity.timezone,
          creator_profile_id: props.activity.profile.id,
          calendar_sync: props.activity.profile.calendar_sync,
          description: props.activity.description.substring(0,100)+ " ...",
          timezone: newMeeting.viewer_timezone,
          cost: props.activity.meeting_cost,
          viewer_name: props.meeting.name,
          activity_title: props.activity.booking_name,
          id: newMeeting.room_uuid,
          brand_logo: props.activity.profile.brand_logo,
          runAt: runAt,
          remindAt: remindAt,
          endAt: appointmentDateTime.setZone(props.meeting.timezone).plus({ minutes: durationInMinutes }).toISO(),
          recurring:props.activity.recurring
        })
      })
        .then(response => {
          if (!response.ok) throw new Error(response.status);
          setConfirmed(true)
          console.log("Register email sent")
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          alert.error("Unable to send email confirmation")
          console.log("Unable to send email confirmation")
          setIsLoading(false)
        })





      //schedule meeting lock
    } else {

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }

    }
  };


  return confirmed ? (
    <div className="pt-5 mt-5 pb-20  text-center">
      <CheckLg className="mx-auto mb-2 text-green-600" size={45} />
      <p className="font-bold text-xl mb-5">{props.activity.multiple ? "Meetings confirmed!" : "Meeting confirmed!"}</p>
      <div>You will receive a confirmation email shortly.<br />
        <small className="text-slate-600">{props.activity.multiple ?
          `You'll also receive a video link
  5 minutes before each meeting.`
          :
          `You'll also receive a video link
  5 minutes before your meeting.`
        }
        </small>
        {user &&
        <div className='mt-5'>
        <Link href="/dashboard">
          <a className="w-full mt-5 bg-slate-700 shadow-md shadow-slate-500/50 rounded-md py-2 px-2 text-sm font-semibold text-white text-center hover:bg-zinc-900">
            View Dashboard</a>
        </Link>
        </div>
        }
      </div>
    </div>
  ) : (

    <div className='mt-4'>
      <form id="payment-form" onSubmit={handleSubmit}>
        {/* <div className={`flex justify-center items-center mt-10 ${loading && 'hidden'}`}>
                <svg className="animate-spin ml-1 mr-3 h-10 w-10 text-zinc-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
          </div> */}
        <PaymentElement
          id="payment-element"
          onReady={() => setTimeout(() => {
            setLoading(true)
          }, 50)}
        />

        {loading &&
          <>
            <div className="text-right text-zinc-200 mt-2 ">
              <div className="flex justify-start" style={{ fontSize: 9 }}>
                <span className="text-zinc-900" >Powered by </span>
                <Image
                  alt="Stripe"
                  src="https://d2j5mnrqpo6nvp.cloudfront.net/static/img/stripe.svg"
                  width={31.55} height={15}

                /></div>
            </div>

            <button className="w-full mt-5 bg-slate-700 shadow-md shadow-slate-500/50 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-zinc-900" disabled={isLoading || !stripe || !elements} type="submit" id="submit">
              <span id="button-text">
                {isLoading ? <div className="flex justify-center"><svg className="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg></div> : "Pay"}
              </span>
            </button>
          </>
        }


        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

    </div>

  );

}

//do i need to add account here?

const Stripe = (props) => {
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret: props.clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {props.clientSecret && (
        <Elements options={options} stripe={props.stripeObject}>
          <Checkout
            activity={props.activity}
            meeting={props.meeting}
            setSubPage={props.setSubPage}

          />
        </Elements>
      )}

    </div>
  );


}



const TimeComponent = (props) => {

  if (props.timeSlots.length < 1) {
    return (
      <div className='text-center' style={{ minHeight: '300px' }}>
        <div style={{ color: 'black' }}>{props.selectedDate}</div>
        <br />
        <p >
          <small>
            No more available appointments.  Please select another date.
          </small>
        </p>
        <br />
        <button className="p-2 rounded-md hover:bg-zinc-700 bg-zinc-900 text-white" onClick={() => props.setSubPage(0)} >Return</button>
      </div>

    )
  }
  return (
    <div className='w-full mx-auto py-6'>

      <div className="flex justify-between" >
        <p>Select a time:</p>
        <div className="flex items-center space-x-2"
          style={{ cursor: 'pointer' }} onClick={() => props.setSubPage(0)}><ArrowLeft /><p>Return</p></div>
      </div>


      <p className='font-bold text-xl mb-3'>{props.selectedDate}</p>


      <div className="space-y-2 overflow-y-auto max-h-48 w-full mx-auto " >
        {props.timeSlots.map((timeSlot) => (
          <div key={timeSlot} className="h-30">
            <button onClick={props.saveAndContinue}
              className="border-2 border-slate-400 p-1 w-full font-semibold text-slate-600 bg-slate-100  rounded hover:bg-slate-400 hover:text-white"
              value={timeSlot}
              name="timeSelected">{timeSlot}</button>
          </div>
        ))}
      </div>

    </div>

  )
}

const Confirmation = (props) => {
  const [confirmed, setConfirmed] = useState(null)
  const [loading, setLoading] = useState(null)
  const alert = useAlert()
  const { user } = useAuth()

  useEffect(() => {

    const getClientSecret = () => {
      if (props.activity.meeting_cost && props.activity.meeting_cost > 0 && !props.clientSecret && props.activity.profile.stripe_confirmed) {
        setLoading(true)
        fetch("/api/stripe/create-payment-intent", { //might need to move this to a different spot
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            service_id: props.activity.id,
            price_id: props.activity.price_id,
            recurring: props.activity.recurring,
            rand: props.meeting.rand,
            marketplace: props.activity.profile.listing_id ? true : false,
            multiple:props.activity.multiple,
            stripe_profile_id: props.activity.profile.stripe_account_id,
            cost: props.activity.meeting_cost,
            billing_name: props.meeting.name,
            billing_email: props.meeting.email,
            client_user_id: user ? user.id : null,
            client_profile_id: props.meeting.client_profile_id ? props.meeting.client_profile_id : null
          }),
        })
          .then((res) => res.json())
          .then(async (data) => {
            props.setClientSecret(data.clientSecret)
            setLoading(false)
          });
      }
    }

    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (props.profile.stripe_account_id) {
        const res = await loadStripe(dev ?
          "pk_test_51Ka5CpHobLlY2U5tPudxWi7VMqEk05x00dujb8tV8rxKbDKv4NSK4TxmLSLWUPH0pHq9mMpQZdRkHsJLgJvj9N6X00heRI1KSG" :
          "pk_live_51Ka5CpHobLlY2U5t2jfPpkigRBV90pmoSucBdvVqet6sAKNBW0I5aNpq0ZfGtFN8uL1yebDxoQRmaWTBzAWYFBdi00GCzTaJP6", { stripeAccount: props.profile.stripe_account_id });
        // When we have got the Stripe object, pass it into our useState.
        props.setStripeObject(res);
      }
    };
    getClientSecret()
    fetchStripeObject()
  }, [])

  const nextStep = async (e) => {
    setLoading(true)
    const { meeting } = props
    if (!meeting.name || !meeting.email) {
      alert.error('Please enter a name and email')
      setLoading(false)
      return
    }
    console.log("runing next step")
    // props.clientSecret && getClientSecret()
    props.setSubPage(4)
  }

  const bookDemo = async (e) => {
    e.preventDefault()
    setLoading(true)


    const { meeting } = props

    if (!meeting.name || !meeting.email) {
      alert.error('Please enter a name and email')
      setLoading(false)
      return
    }

    const { dateStr, appointment_datetime, timezone } = meeting
    const durationInMinutes = props.activity.duration_period === "minutes" ? props.activity.duration_int : props.activity.duration_int * 60
    const duration_obj = { minutes: durationInMinutes }
    const appointmentDateTime = DateTime.fromISO(appointment_datetime)
    const utc_date = appointmentDateTime.toUTC().toFormat('y-L-d-H-m').split("-").map(str => parseInt(str))

    const payload = {
      appointment_datetime: appointmentDateTime.toISO(),
      viewer_name: meeting.name,
      viewer_email: meeting.email,
      notes: meeting.notes,
      status: 'upcoming',
      duration: durationInMinutes,
      viewer_timezone: timezone,
      type: '1-on-1',
      subtype: meeting.rand,
      user_id: props.activity.user_id,
      activity: props.activity.id,
      person: meeting.person,
      client_user_id: user ? user.id : null,
      voice_call: props.voiceCall
    }


    const { data, error } = await supabase
      .from("main_meeting")
      .insert(payload)

    if (!error) {
      console.log("no error")
      
      ga.event({
        action:'book_call',
      })
      //send register email
      const newMeeting = data[0]
      fetch('/api/contact/demo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: newMeeting.viewer_email,
          meeting_data: {
            uuid: newMeeting.room_uuid,
            creator_iso: appointmentDateTime.setZone(props.activity.timezone).toISO(),
            v_appointment_datetime: appointmentDateTime.setZone(props.meeting.timezone).toLocaleString(DateTime.DATETIME_FULL),
            c_appointment_datetime: appointmentDateTime.setZone(props.activity.timezone).toLocaleString(DateTime.DATETIME_FULL)
          },
          creator_email: props.activity.profile.email,
          notes: newMeeting.notes,
          utc_date: utc_date,
          subtype: meeting.rand,
          duration_obj: duration_obj,
          creator_name: props.activity.profile.name,
          creator_timezone: props.activity.timezone,
          creator_profile_id: props.activity.profile.id,
          calendar_sync: props.activity.profile.calendar_sync,
          description: props.activity.description.substring(0,100)+ " ...",
          timezone: newMeeting.viewer_timezone,
          viewer_name: props.meeting.name,
          activity_title: props.activity.booking_name,
          id: data[0].room_uuid,
          voice_call: props.voiceCall,
          brand_logo: props.activity.profile.brand_logo,
          runAt: DateTime.now().plus({ minutes: 1 }).toISO(),
          remindAt: appointmentDateTime.setZone(props.meeting.timezone).plus({ minutes: -60 }).toISO(),
          endAt: appointmentDateTime.setZone(props.meeting.timezone).plus({ minutes: durationInMinutes }).toISO(),
        })
      })
        .then(response => {
          if (!response.ok) throw new Error(response.status);
          setConfirmed(true)
          console.log("Register email sent")
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
          alert.error("Unable to send email confirmation")
          console.log("Unable to send email confirmation")
          setLoading(false)
        })

    } else {
      console.log(error)
    }

  }

  return (
    <>
      {confirmed ?
        <div className="pt-5 mt-5 pb-20  text-center">
          <CheckLg className="mx-auto mb-2 text-green-600" size={45} />
          <p className="font-bold text-xl mb-5">Meeting confirmed!</p>
          <p>You will receive a confirmation email shortly.<br />
            <small className="text-slate-600"> You&apos;ll also receive a video link
              5 minutes before your upcoming meeting.</small>
          </p>
        </div>

        :
        <div className={`h-96    `}>
          <div className="text-center">
            {props.activity.multiple ?
              <p className='font-semibold text-xl mb-3 text-slate-800'>
                Enter details
              </p>
              :
              <>
                <p className='font-bold text-xl mb-3'>{props.meeting.dateStr}</p>
                <p className='-mt-4 text-slate-600'>{props.meeting.timezone}</p>
              </>

            }
          </div>
          <form className='text-left'>
            <div className="mb-3">
              <p className="label">Name</p>
              <input
                type="text"
                required
                name="name"
                className="mt-1 px-3 py-2 disabled:bg-slate-200 disabled:text-slate-500 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
                defaultValue={props.meeting.name}
                onChange={props.handleChange}
                disabled={loading || user}
              />
            </div>
            <div className="mb-3">
              <p className="label">Email</p>
              <input
                type='email'
                required
                name="email"
                className="mt-1 px-3 py-2 disabled:bg-slate-200 disabled:text-slate-500 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
                disabled={(props.meeting.viewer_profile ? true : loading ? true : false) || user}
                defaultValue={props.meeting.email}
                onChange={props.handleChange}
              />
            </div>

          

            <div className="mb-3">
              {/* {props.business && " (Ex: request a template or demo) "} */}
              <p className="label">Anything you&apos;d like to share?</p>
              <textarea
                rows={5}
                size="sm"
                name="notes"
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded-md sm:text-sm focus:ring-1"
                defaultValue={props.meeting.notes}
                onChange={props.handleChange}
                // disabled={loading}
              />
            </div>
          </form>
          <div className="text-end mb-10">
            <button
              className="w-full mt-5 bg-slate-700 shadow-md shadow-slate-500/50 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-zinc-900"
              onClick={(props.activity.meeting_cost && props.activity.meeting_cost > 0 && props.activity.profile.stripe_confirmed) ? nextStep : bookDemo} disabled={loading}>
              {loading ? <div className="flex justify-center"><svg className="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg></div> : (props.activity.meeting_cost && props.activity.meeting_cost > 0 && props.activity.profile.stripe_confirmed) ? 'Continue to Billing' : 'Set Appointment'}
            </button>
          </div>

        </div>

      }
    </>


  )
}

const MeetingPreview = (props) => {
  const [previewMeetings, setPreviewMeetings] = useState([])
  const container = useRef()

  const isOverflown = () => {
    const element = container.current
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  useEffect(() => {
    let newMeetings = []
    const totalWeeks = props.activity.schedule_period === "week" ? props.activity.schedule_int : props.activity.schedule_int * 4
    let allMeetingDates = []
    let selectedDatesForWeek = [...props.meeting.meeting_dates]
    let index = 0
    for (let i = 0; i < totalWeeks; i++) {
      let newWeek = []
      for (let j = 0; j < props.activity.weekly_meeting_amount; j++) {
        newWeek.push(selectedDatesForWeek[j])
        allMeetingDates.push(selectedDatesForWeek[j])
        selectedDatesForWeek[j] = DateTime.fromISO(selectedDatesForWeek[j]).plus({ weeks: 1 }).toISO()
        index++
      }
      newMeetings.push(newWeek)
    }

    //handle meeting
    const ev = {
      target: {
        name: "meeting_dates",
        value: allMeetingDates
      }
    }
    props.handleChange(ev)
    setPreviewMeetings(newMeetings)

  }, [])




  return (
    <div className=''>
      <h2 className='text-lg font-bold mb-3'>Does this schedule work for you?</h2>
      {props.activity.recurring && <p className="text-slate-500 text-xs italic -mt-3">*This schedule will repeat monthly. Rescheduling available when needed</p>} 
      {previewMeetings.length < 1 ?
        <div className="flex justify-center"><svg className="animate-spin ml-1 mr-3 h-5 w-5 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg></div>
        :
        <>
          <div className="h-72 overflow-y-scroll " ref={container}>


            {previewMeetings.map((arr, index) => (
              <div className='mb-2' key={`pm-${index}`}>
                <p className=' font-semibold text-slate-500'>Week {index + 1}</p>
                {arr.map(str => (
                  <p className="text-sm"
                    key={str}>{DateTime.fromISO(str).setZone(props.meeting.timezone).toFormat("fff")} </p>
                ))}

              </div>

            ))

            }

          </div>
          <button
            className="w-full mt-5 bg-slate-700 shadow-md shadow-slate-500/50 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-zinc-900"
            onClick={props.nextStep} >
            Continue
          </button>
        </>

      }

    </div>
  )
}

const Calendar = (props) => {
  const [activity, setActivity] = useState(null)
  const [meetings, setMeetings] = useState(null)
  const [stripeObject, setStripeObject] = useState(null)
  const alert = useAlert()
  const { user } = useAuth()



  useEffect(() => {
    const getActivity = async () => {
      const { data, error } = await supabase
        .from("main_activity")
        .select(`id, user_id, start_date, booking_slug, price_id, description, messaging, recurring, meeting_cost, multiple, require_signup, weekly_meeting_amount, sessions, schedule_period, schedule_int, student_limit, session_amount, status, booking_name, duration_int, duration_period, type, timezone, main_opening_hours (opening_time, closing_time, days),
            future_limit_int, buffer_time_int, buffer_time_period, future_limit_period, duration_int, duration_period, min_booking_notice_int, min_booking_notice_period, profile (id, name, calendar_sync, full_name, email, user_image, brand_logo, stripe_confirmed, stripe_account_id, events, listing_id (id, type))
            `)
        .eq('booking_slug', props.slug)
        console.log(props.slug)
      if (data[0].require_signup) {
          setSubPage(-2)
        } 
      error && console.log(error)

      const meetings = await supabase
        .from("main_meeting")
        .select(`user_id, appointment_datetime, duration, activity, room_uuid, status, type`)
        .in("status", ["upcoming", "live"])
        .eq("user_id", data[0].user_id)

      meetings.error && console.log(meetings.error)

      setMeetings(meetings.data)
      setActivity(data[0])
    }
    if (!props.activity) {
      getActivity()
    } else {
      setActivity(props.activity) //should probably change this
      setMeetings(props.meetings)
    }
  }, [])

  useEffect(()=> {
    const getProfile = async (u) => {
      const {data, error} = await supabase
        .from("main_profile")
        .select("id, name, user_id, email")
        .match({user_id: u.id})
      props.handleUser({email: data[0].email, name:data[0].name, id: u.id, profile_id: data[0].id})
      console.log("user found")
      if (props.subPage !== -1) {
        props.setSubPage(0)
      }
    }
    if (user && props.checkUser && activity) {
      getProfile(user)
    }

  },[activity])

  const nextStep = () => {
    props.setSubPage(prevState => {
      return prevState + 1
    })
  }

  const checkMeetings = (meets) => {
    let sortedArr = meets.sort((a, b) => {
      return (a < b) ? -1 : ((a > b) ? 1 : 0);
    });

    const start = DateTime.fromISO(sortedArr[0])
    const end = DateTime.fromISO(sortedArr[sortedArr.length - 1])
    const diffInDays = end.diff(start, 'days')

    if (diffInDays.days > 7) {
      alert.error(`Select ${activity.weekly_meeting_amount} dates in the same week`)

      return false
    }
    console.log("returinng true")
    return true

  }

  const saveAndContinue = async (e) => {
    e.preventDefault();
    let newMeeting = { ...props.meeting }
    newMeeting.dateStr = newMeeting.dateStr + ' ' + e.target.value
    const dt = DateTime.fromFormat(newMeeting.dateStr, 'DD h:m a').setZone(props.meeting.timezone) //might need to fix this???

    if (activity.multiple) {
      newMeeting.meeting_dates.push(dt.toISO())

      props.setMeeting(newMeeting)
      if (newMeeting.meeting_dates.length >= activity.weekly_meeting_amount) {
        if (checkMeetings(newMeeting.meeting_dates)) {
          props.setSubPage(2)
        } else {
          //handle error here
          newMeeting.meeting_dates.pop()
          props.setSubPage(0)
        }

      } else {
        props.setSubPage(0)
      }

    } else {
      newMeeting.appointment_datetime = dt.toISO()
      props.setMeeting(newMeeting)
      props.setSubPage(3)

    }

  }


  const addTimeSlots = (dateStr) => {

    //get associated opening hours from click
    const daySelected = DateTime.fromISO(dateStr)
    const dayInCreatorTimeZone = daySelected.setZone(activity.timezone);

    const { duration_int, duration_period, buffer_time_int, buffer_time_period, main_opening_hours, min_booking_notice_int, min_booking_notice_period } = activity
    const durObj = {[duration_period]: duration_int }
    const bufferObj = { [buffer_time_period]: buffer_time_int }
    const minObj = { [min_booking_notice_period]: min_booking_notice_int }
    const blocked = meetings.filter(obj => new Date(obj.appointment_datetime).today === new Date().today)  //change to user timmezone

    const calEvents = activity.profile?.events

    var booked = []


    //add blocked here
    if (calEvents) {
      for (const cal of calEvents) {
        const b = DateTime.fromISO(cal.start.dateTime).setZone(props.meeting.timezone)
        var diff = DateTime.fromISO(cal.end.dateTime).diff(DateTime.fromISO(cal.start.dateTime), "minutes").toObject()
        const minutes = diff.minutes
  
        booked.push({ dt: b, data : {duration: minutes } })
  
      }
    }


    if (blocked) {
      for (const block of blocked) {   //add all booked times according to user timeZone
        const blockDt = DateTime.fromISO(block.appointment_datetime).setZone(props.meeting.timezone)
        if (blockDt.day === daySelected.day && blockDt.year === daySelected.year && blockDt.month === daySelected.month) {
          booked.push({ dt: blockDt, data: block })
        }

      }
    }

    var timeStops = [];
    const currDate = DateTime.now().setZone(props.meeting.timezone)
    const minDate = currDate.plus(minObj)
    for (const openingHours of main_opening_hours) {
      // #add this validation to days BEFOREHAND at some point!!!
      if (openingHours.days.includes(dayInCreatorTimeZone.toFormat('cccc'))) {
        const openTimeArr = openingHours.opening_time.split(":")  //parse date string
        const closeTimeArr = openingHours.closing_time.split(":")
        const oTime = dayInCreatorTimeZone.set({ hour: openTimeArr[0], minute: openTimeArr[1] }) //set time
        const cTime = dayInCreatorTimeZone.set({ hour: closeTimeArr[0], minute: closeTimeArr[1] })

        var userOTime = oTime.setZone(props.meeting.timezone)
        let userCTime = cTime.setZone(props.meeting.timezone)
        let dateCheck = userOTime.plus(durObj)
        let excluded = []

        while (dateCheck < userCTime) {
          const timeSlot = userOTime

          //check if time is before minimum booking notice
          if (timeSlot > minDate) {
            for (const b of booked) {
              // if (timeSlot.toFormat('h:mm a') !== b.dt.toFormat('h:mm a')) {
              //   console.log(b.dt.toFormat('h:mm a'), timeSlot.toFormat('h:mm a'))
              // }


              //check if:
              // are there a booked meetings this day
              // is booked at the same time
              // does not interfere with buffer time before or after

              //check middle condition since buffer object need to be factored in
              if ((timeSlot !== b.dt && timeSlot.plus(durObj).plus(bufferObj) < b.dt.minus(bufferObj)) || b.dt.plus({ minutes: b.data.duration }).plus(bufferObj) <= timeSlot) {

                timeStops.push(timeSlot.toFormat('h:mm a'))
                continue
              } else {
                excluded.push(timeSlot.toFormat('h:mm a'))
              }
            }
            if (booked.length === 0) {
              timeStops.push(timeSlot.toFormat('h:mm a'))
            }
          }
          userOTime = userOTime.plus(durObj);
          dateCheck = dateCheck.plus(durObj)
        }
      }
    }
    timeStops = timeStops.filter(obj => !excluded.includes(obj))
    timeStops = [...new Set(timeStops)];
    timeStops.sort((a, b) => Date.parse('01/01/2012 ' + a) - Date.parse('01/01/2012 ' + b));  //sorts times in array from earliest to latest


    return timeStops
  }


  if (!activity || !meetings) {
    return (
      <div className="flex justify-center">
                 <svg className="animate-spin h-10 w-10 my- text-zinc-500 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                 </svg> 
         </div>
    )
  }


  return (
    <>

      <div className={`text-center bg-white z-4 h-full w-full relative  `} >
 

        <div className="text-left w-full  flex-col md:space-x-5 md:flex-row flex justify-between items-center relative">
          {/* {activity.video_template.logo && 
                        <div className='flex justify-start w-full absolute -top-10 '>
                            <Image 
                            alt="Logo" 
                            src={"https://d28zq0um2frnpx.cloudfront.net/" + activity.video_template.logo} 
                            width={64} height={32} 
                            objectFit="contain"
                        />
                        </div>
                  } */}
          {props.business &&
          <div className=' w-3/4 sm:w-1/2 relative px-5 sm:px-10 py-5 ' >
          {activity.profile.brand_logo &&
          <div className=' mb-5 flex w-full justify-center'>
            <Image
              src={`${cloudfront}/${activity.profile.brand_logo}`}
              width={70} height={35}
              alt={activity.profile.name}
              objectFit="contain"
            />
          </div>

        }



            <div className='flex  flex-col text-2xl font-semibold items-start mb-3'>
              {activity.profile.user_image &&
                <Image
                  className={userStyles.img_src}
                  src={`${cloudfront}/${activity.profile.user_image}`}
                  width={50} height={50}
                  alt={activity.profile.username}
                  objectFit="cover"
                />

              }

              <h5 className='text-lg font-semibold text-slate-800'>{activity.profile.name}</h5>

            </div>

            <h2 className="font-extrabold text-2xl text-gray-900  ">{activity.booking_name}</h2>



            {activity.description &&
              <p className='text-sm w-full text-zinc-500 mb-3'>{activity.description}</p>

            }

            <div className="mb-3 flex-col space-y-1 text-slate-500">
              <div className="flex space-x-2 items-center">
                <CameraVideoFill className='' size={16} /><p className="text-sm font-semibold">Video Conference</p>
              </div>
              <div className="flex space-x-2 items-center">
                <ClockFill className='' size={16} /><p className="text-sm font-semibold">{activity.duration_int} {activity.duration_period}</p>
              </div>
              {(activity.multiple && activity.profile.stripe_confirmed && activity?.meeting_cost > 0) && (
                <div className="flex space-x-2 items-center">
                  <CalendarWeek className='' size={16} />
                  {activity.recurring ? 
                        <p className="text-sm font-semibold">
                        {activity.weekly_meeting_amount} {activity.weekly_meeting_amount > 1 ? "sessions" : "session"} a week
                      </p>
                  :
                  <p className="text-sm font-semibold">
                  {activity.weekly_meeting_amount} {activity.weekly_meeting_amount > 1 ? "sessions" : "session"} a week for {activity.schedule_int} {activity.schedule_int > 1 ? activity.schedule_period + "s" : activity.schedule_period}
                </p>
                  
                  }
    
                </div>
              )}
              {(activity.meeting_cost && activity.meeting_cost > 0 && activity.profile.stripe_confirmed) ? (
                <div className="flex space-x-2 items-center">
                  <CurrencyDollar className='' size={16} /><p className="text-sm font-semibold">{activity.meeting_cost}{activity.recurring && "/mo"}</p>
                </div>
              ) : <p></p>}
              {activity.messaging && 
              <div className='flex space-x-2 items-center'>
                <EnvelopeFill />

                <span onClick={()=> props.setSubPage(-1)} className='text-sm font-semibold hover:underline'>Ask a question</span>

              </div>
              }
            </div>
          </div>
          }

          <div className={` ${props.business ? "sm:py-10 w-full sm:px-20 md:px-10 md:w-1/2 bg-slate-200"  : " w-full bg-slate-100" } pt-10 px-4 `} style={{minHeight:"464px"}}>
            {props.subPage === 0 || props.subPage === 1 &&
              <div className="text-center w-full flex justify-center items-center space-x-3 mb-1 mt-5">
                <div className='w-full '>
                  <TimezoneSelect
                    value={props.meeting.timezone}
                    onChange={props.handleTimeChange}
                  />
                </div>
              </div>
            }

          {props.subPage === -2 && 
                  <RegisterSlide activity={activity}
                  setCheckUser={props.setCheckUser}
                  handleUser={props.handleUser} profile={activity.profile} setSubPage={props.setSubPage}/>
            }
            {props.subPage === -1 && 
                  <SendMessage activity={activity} profile={activity.profile}/>
            }


            {props.subPage === 0 &&
              <div className=' -ml-5 '>
                {activity.multiple ?
                  <div className='mb-5 pt-2'>
                    <h2 className={`font-semibold text-lg pt-2 text-slate-900 text-center`}>{activity.weekly_meeting_amount > 1 ? `Select ${activity.weekly_meeting_amount} dates in the same week` : `Select a date`}</h2>
                    {props.meeting.meeting_dates.map((obj, index) => (
                      <p className="text-slate-600 text-sm border text-center mb-1 p-1 rounded bg-slate-100 border-slate-400"
                        key={obj}>Date {index + 1 + ": "}{DateTime.fromISO(obj).setZone(props.meeting.timezone).toFormat("fff")}</p>
                    ))}
                  </div>
                  :
                  <p className='font-semibold text-lg pt-2 text-slate-900 text-center'>
                    Select a date
                  </p>
                }
                <div className='mt-5'>
                <Flatpickr
                  data-enable-time
                  options={{
                    minDate: 'today',
                    //Displays the calendar at all times
                    inline: true,
                    //what's displayed in the input
                    altFormat: 'Z',
                    // enable dates
                    enable: [
                      function (date) {
                        //check if today is disabled or not
                        const dateDay = date.getDay()
                        let days = []
                        let dayInts = []

                        const copy = [...activity.main_opening_hours]
                        for (const obj of copy) {
                          days = days.concat(obj.days)
                        }

                        const dayMap = {
                          "Sunday": 0,
                          "Monday": 1,
                          "Tuesday": 2,
                          "Wednesday": 3,
                          "Thursday": 4,
                          "Friday": 5,
                          "Saturday": 6
                        }
                        for (const str of days) {
                          dayInts.push(dayMap[str])
                        }
                        return (dayInts.includes(dateDay))  //fix this to adjust for timezone
                      }
                    ],
                    dateFormat: "Y-m-d",
                    //Show the user a readable date (as per altFormat), but return something totally different to the server.
                    altInput: false,
                    //show time on calendar
                    enableTime: false,
                    //# of days available (only takes in weeks)
                    maxDate: new Date().fp_incr(activity.future_limit_period === "weeks" ? activity.future_limit_int * 7 : activity.future_limit_int)
                  }}
                  // handle input change event
                  onChange={(selectedDates, dateStr, instance) => {
                    const dtArr = dateStr.split('-')
                    let dt = DateTime.fromObject({ year: dtArr[0], month: dtArr[1], day: dtArr[2] }, { zone: props.meeting.timezone })
                    props.handleDateStr(dt);
                    props.setSubPage(1)
                  }
                  }
                />
                </div>
              </div>
            }

            {props.subPage === 1 &&

              <TimeComponent
                selectedDate={props.meeting.dateStr}
                timeSlots={addTimeSlots(props.meeting.appointment_datetime)}
                activity={activity}
                saveAndContinue={saveAndContinue}
                handleChange={props.handleChange}
                setSubPage={props.setSubPage}
                business={props.business}
              />

            }
            {props.subPage === 2 &&

              <MeetingPreview
                meeting={props.meeting}
                activity={activity}
                saveAndContinue={saveAndContinue}
                handleChange={props.handleChange}
                nextStep={nextStep}
                setSubPage={props.setSubPage}
                business={props.business}
              />

            }



            {props.subPage === 3 &&

              <Confirmation
                meeting={props.meeting}
                voiceCall={props.voiceCall}
                handleChange={props.handleChange}
                activity={activity}
                profile={activity.profile}
                business={props.business}
                setSubPage={props.setSubPage}
                clientSecret={props.clientSecret}
                setClientSecret={props.setClientSecret}
                setStripeObject={setStripeObject}
              />}

            {props.subPage === 4 &&

              <Stripe
                meeting={props.meeting}
                handleChange={props.handleChange}
                activity={activity}
                profile={activity.profile}
                business={props.business}
                setSubPage={props.setSubPage}
                clientSecret={props.clientSecret}
                stripeObject={stripeObject}
              />}



          </div>
        </div>


        <style global jsx>{`
  a {
    cursor: pointer !important;
  }
  /*dateStr selected that appears above time slots*/
  .flatpickr-dateStr {
      font-size: 115%;
      line-height: inherit;
      font-weight: 300;
      color: #484848 !important;
      position: static;
      width: 75%;
      left: 12.5%;
      padding: 7.48px 0 0 0;
      line-height: 1;
      height: 34px;
      display: inline-block;
      text-align: center;
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
  }
  
  
  
  /*flatpickr*/
  .flatpickr-days, .flatpickr-innerContainer {
      border:none !important;
      background:none;
      display:inline-block !important;
  }

  .flatpickr-current-month {

    font-size:16px;
    color:#484848 !important;
  

}
  
  .flatpickr-month, .flatpickr-monthDropdown-months, .cur-year {
      background:none !important;
      color:#484848 !important;
      font-weight: 500 !important;

  }
  
  .flatpickr-weekdays, .flatpickr-weekday {
    background:none !important;
    text-transform: uppercase;
    color:#484848 !important;
  
  }
  
  .flatpickr-input {
    display:none !important;
  }
  
  .flatpickr-day.selected {
    background: rgb(49, 35, 101) !important;
    border-color: rgb(49, 35, 101)!important;
  }
  
  .flatpickr-prev-month, .flatpickr-next-month {
    fill:black !important;
  }
  
  .flatpickr-monthDropdown-month {
    background-color: white !important;
  }
  
  .flatpickr-day :not(.flatpickr-disabled){
    background: rgb(49, 35, 101) !important;
    border: rgb(49, 35, 101) !important;
  }
  
  .flatpickr-day {
    font-weight: 600;
  }
  
  .flatpickr-day:hover, .flatpickr-day.today:hover {
    background-color: rgb(226 232 240) !important;
    border-color: #ffffff;
    border-width: 2px;
    color:black;
    
  }
  
  .flatpickr-day.today {
    border:1.5px solid #BBBBB;
  }
  
  .flatpickr-calendar {
    box-shadow:none;
    border:none;
    font-weight: normal;
    margin:auto;
    width: auto;
  }
  
  @media only screen and (max-width: 340px){
     .flatpickr-calendar {
      margin:auto;
      width: auto;
    }
    .flatpickr-innerContainer {
      overflow: scroll !important;
      width:250px;
      margin:auto;
    }
  }
  
  @media only screen and (max-width: 290px){
     .flatpickr-calendar {
      margin:auto;
      width: auto;
    }
    .flatpickr-innerContainer {
      overflow: scroll !important;
      width:200px;
      margin:auto;
    }
  }
  
  @media only screen and (max-width: 600px){
    .time-col {
      overflow-y: scroll;
      height:400px !important;
    }
  }
  
  .font {
    font-size: 13.3333px !important;
    font-weight: 400 !important;
  }
  
  /*scrollbar for time slots*/
  .time-col {
    overflow-y: scroll;
    height:290px;
  }
  
  /*scrollbar width */
  ::-webkit-scrollbar {
    width:0px;
  }
  
  /*all form input labels*/
  .label {
      font-size: 90%;
      color: grey;
      line-height: 1;

  }
  
  /*stripe*/
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .hidden {
    display: none;
  }
  
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  #payment-request-button {
    margin-bottom: 32px;
  }
  

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  
  /*react-bootstrap timezonepicker*/
  .timezone-picker-textfield input {
    border:none;
    background:none;
    width:200px;
  }
  
  /*moment.js line break */
  #appDetails {
    white-space: pre;
  }
  
  /*schedule page card*/
  .schedule-card {
    width: 21rem; 
    height:10rem;
    background-color: #F4E7EC;
    border-style: solid;
    border-width:3px;
    color:rgb(97, 8, 42);
    border-radius: .3rem !important;
  }
  
  
  /*add schedule page card*/
  .schedule-card-add {
    background-color: #fff !important;
    border-style: dashed;
  }
  
  .schedule-card-add:hover{
    background-color:#F4E7EC !important;
    text-decoration: none;
  }
  
  /*add schedule page card text vertically centered in box*/
  .center-schedule {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
  }
  
  /*center schedule boxes in the column*/
  .item-center {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    align-items: center;
    justify-items: center;
  }
  
  

`}</style>
      </div>

    </>

  )
}

export default Calendar;

